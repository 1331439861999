<template>
  <div class="Login">
    <v-snackbar v-model="snackbar" :timeout="2000" top>
      {{ snackMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <h1>Login</h1>
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>
    <v-text-field v-model="User.username" label="username"></v-text-field>
    <v-text-field
      v-model="User.password"
      type="password"
      label="password"
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="doLogin()">Login</v-btn>
    <v-progress-circular
      v-if="progress"
      :width="3"
      color="red"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import Vue from "vue";
import md5 from "md5";
import axios from "axios";
import VueAxios from "vue-axios";

import postLoginAxios from "../../services/axios-post-login";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      snackMessage: "",
      snackbar: "",
      color: "",
      User: {
        username: "",
        password: "",
      },
      type: null,
      alertMsg: null,
      progress: null,
    };
  },
  methods: {
    async doLogin() {
      var self = this;
      this.progress = true;
      const params = {
        email: self.User.username,
        password: md5(self.User.password),
      };
      postLoginAxios(
        `${process.env.VUE_APP_SERVER_URL}/admin/adminLogin`,
        params,
        ""
      )
        .then(function(res) {
          console.log(res);
          localStorage.setItem("jwt", res.data.data.jwt);
          localStorage.setItem("admin_id", res.data.data.userId);
          self.showAlert("success", "Login successfully");
          self.progress = false;
          window.location.href = "./#/used-car";
        })
        .catch(function(error) {
          self.showAlert("error", "Invalid Credentials");
          self.progress = false;
          if (error.response) {
            //   console.log(error.response.data);
            //   console.log(error.response.status);
            //   console.log(error.response.headers);
          }
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    showSnack(msg, color) {
      this.snackMessage = msg;
      this.color = color;
      this.snackbar = true;
    },
  },
};
</script>
